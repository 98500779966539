import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "personnel-tickets"
    }}>{`Personnel tickets`}</h1>
    <p><a parentName="p" {...{
        "href": "#csv-import"
      }}>{`Import via CSV`}</a>{`
`}<a parentName="p" {...{
        "href": "#reports"
      }}>{`Fetching reports of savings`}</a></p>
    <p>{`The personnel ticket system ("pbsys") is used to `}</p>
    <ul>
      <li parentName="ul">{`administer employees in the companies ("companies") involved in the personnel ticket agreement. `}</li>
      <li parentName="ul">{`calculate whether an employee is entitled to a personnel ticket and`}</li>
      <li parentName="ul">{`create the necessary ticket rights in the internal Entur systems for the users.`}</li>
    </ul>
    <p>{`It has an API that is primarily used for internal users, but some endpoints are relevant for the
companies. Specifically, at this time we expose endpoints for importing employees to support tax reporting.`}</p>
    <h2 {...{
      "id": "csv-import"
    }}>{`CSV import`}</h2>
    <p>{`For those companies not yet prepared to connect directly to the REST api, we've created an option for CSV imports.
Please see the following section for more information of the format.`}</p>
    <p>Example of a valid csv file containing three employees is available here: <a href={`/static/personalbillett/import_employees_example.csv`} download>Example csv</a>.<br />
The corresponding Excel file is here: <a href={`/static/personalbillett/import_employees_example.xlsx`} download>Example xlsx</a></p>
    <h3 {...{
      "id": "csv-import-format"
    }}>{`CSV import format`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`field name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`notes`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employeeNo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your internal employee number for this employee.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`companyId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your company id in the pbsys system. This is `}<em parentName="td">{`not`}</em>{` the same as your partner id.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employmentTypeId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of employment. See `}<strong parentName="td">{`Employment Types`}</strong>{` below.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dateOfBirth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In format "yyyy-MM-dd": "1974-11-23"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ssn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5-digit ssn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`firstName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`First name of employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`surname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Surname of employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`addressLine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Street address of employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`postalCode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Postal code of employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`abstainFromTicket`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether this employee has opted out. Valid values are true and false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hireStartDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of hire. Format "yyyy-MM-dd", "1974-11-23"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`payType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The pay category. See `}<strong parentName="td">{`Pay types`}</strong>{` below.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hireEndDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The day the employee no longer works for the company. Format as hireStartDate.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hireEndCause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The reason for end of hire. See `}<strong parentName="td">{`End causes`}</strong>{` below.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabilityGrade`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The percent disability. This information is treated as sensitive personal data in our systems. A number from 0 to 100.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabilityFromDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The day the disability is valid for personnel ticket purposes. Required if disabilityGrade > 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employmentPercentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The degree of employment for this employee.  A number from 0 to 100.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employmentPercentageDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date the above employment percentage is valid for personnel ticket calculations. Format as hireDate.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`paidPercentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The percentage of pay this employee earns. This is optional, but in some cases must be supplied if it's different than the employmentPercentage.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leaveFromDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If an employee is on leave, it may influence the personnel ticket calculations. Format as hireStartDate.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`""`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDeleted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether this employee should be considered deleted in the pbsys system. Deleted employees are not issued new tickets and current tickets are withdrawn. Valid values are true or false.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Note: Default = "-" means it is a required field.`}</p>
    <h3 {...{
      "id": "employment-types"
    }}>{`Employment Types`}</h3>
    <p>{`These are the valid EmploymentTypes:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Norwegian notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fast ansatt i stilling`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Midlertidig tilsatt i stilling`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tilkallingsvikar/sesong`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ukjent tjenesteforhold`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "categories"
    }}>{`Categories`}</h3>
    <p>{`The categories field currently accepts these values:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bronze ticket`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`silver ticket`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "pay-types"
    }}>{`Pay types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`norwegian notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ukjent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aktiv stilling`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Delvis omsorgspermisjon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Red. arbeidstid etter AML 46A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fødselspermisjon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lånt ut til annen enhet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Perm. for militær/siviltj`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Perm. for annen stilling i NSB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Omsorgspermisjon uten lønn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Militær/siviltj. uten lønn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Studiepermisjon uten lønn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Studiepermisjon m/lønn`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tjenestegj. i høyere stilling`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permisjon m/uførepensjon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`V`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Delvis uførepermisjon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Delvis overgang til AFP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Permisjon, annet u/lønn"`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "end-causes"
    }}>{`End causes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Norwegian notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overg. alderspensj. (kode 341)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slutt v/overg til AS (kode 350`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dødsfall (aksjonskode 320)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Egen oppsigelse (kode 310+330)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overg. AFP/FTP (kode 349)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`I`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Oppsagt v/innskr. (kode 352)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mangler årsaksangivelse`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`O`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slutt l/opplæring (kode 353)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Særaldersgrense (kode 345)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Dødsfall, tj.ulykke (kode 321)"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uførepensj./Opps. v/sykd. K361`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`V`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slutt 6 mnd lønn (kode 354)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slutt annen årsak (kode 350)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ventelønn 30 års tjeneste`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Z`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Avskjed (kode 351)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "reports"
    }}>{`Reports`}</h2>
    <p>{`A major reason for the personnel ticket system is tracking the travels for tax purposes.`}</p>
    <p>{`We've exposed a single endpoint for fetching this report, detailed in the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io?url=https://api.staging.entur.io/api-docs/personnelticket#/FIP/getTaxSavingsReportAsCsvFile"
      }}>{`API documentation`}</a>{`.
If the report is not yet made, it will return 404. To start generating report and get generation progress call the same uri with POST, see `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io?url=https://api.staging.entur.io/api-docs/personnelticket#/tax/createTaxSavingsReport"
      }}>{`Api documentation`}</a></p>
    <p>{`The contents are on this format, a comma-separated CSV with these fields:`}</p>
    <pre><code parentName="pre" {...{}}>{`companyId,employeeNo,ssn,saving,fromDate,toDate,employeeStatus
3,1234,14104512345,230.00,2020-02-01,2020-02-29,P
3,1233,23047854321,1102.00,2020-02-01,2020-02-29,A
`}</code></pre>
    <p>{`Where the fields have this meaning:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Comment`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`companyId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal ID of the company in question. See list below.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is useful for those clients with access to multiple companies.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employeeNo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee Number for this row as supplied by the company`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Note: This may not be correct. Some companies reuse employee numbers when an employee retires, and in that case, the administration may have assigned a unique number or 0 here for those pensioners. This may be negative in those cases.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ssn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (11 digits)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Social security number for the employee`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some pensioners are registered without full social security number. In those cases, the format is birthdate (ddMMyy) + "00000".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`saving`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string (xxxxx.xx)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Savings for the given month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any refunds have been subtracted, so this may occasionally become negative for a month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fromDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO string (yyyy-MM-dd)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`first date in report, inclusive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`toDate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO string (yyyy-MM-dd)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`last date in report, inclusive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`employeeStatus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One character, "P", "A" or "E"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Active employee, Pensioner or bereaved (Etterlatt)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is determined based on whether we have a registered termination code`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "companies"
    }}>{`Companies`}</h2>
    <p>{`This is the list of company ids used for both import and export:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Company name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Companyid`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BaneNOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BaneService`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CargoNet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Flytoget`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gjøvikbanen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Go-Ahead Norge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Jernbaneforbundet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mantena`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lokførerskolen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Norske Tog`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SJ Norge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spordrift`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Agilia`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vy Ansatte`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      